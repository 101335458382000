/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TransactionsService {
    /**
     * @returns any
     * @throws ApiError
     */
    public static listTransactions({
        tokenAddress,
        accountAddress,
        limit,
        page,
    }: {
        /**
         * Token address sale address
         */
        tokenAddress?: string,
        /**
         * Filter Transaction Made by this account address
         */
        accountAddress?: string,
        limit?: number,
        page?: number,
    }): CancelablePromise<Pagination> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/transactions',
            query: {
                'token_address': tokenAddress,
                'account_address': accountAddress,
                'limit': limit,
                'page': page,
            },
        });
    }
}
